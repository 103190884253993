<template>
  <div>
    <b-nav-item @click="showModal = true" title="Ayuda">
      <feather-icon size="21" icon="HelpCircleIcon"/>
    </b-nav-item>

    <b-modal
        id="help-modal"
        v-model="showModal"
        title="Haz clic en el icono de Play para reproducir el video."
        size="lg"
        hide-footer
      >
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          :src="convertedUrl"
          allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BNavItem, BModal, } from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import router from '@/router'
import store from '@/store'

export default {
  name: 'HelpToggler',
  components: {
    BNavItem,
    BModal,
  },
  computed: {
    isOnlyAdmin() {
      const user = this.$store.getters['auth/getUser']
      return user && (user.customRol.id === '849fb91e-cab4-4894-a1c3-aedf03b47c34' || user.customRol.id === "2d7f49c8-ad48-4c4f-8def-fb06fc68143c")
    },
    isAdministrator() {
      return store.getters['auth/getUser'] ? 
        (store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] || store.getters['auth/getUser'].customRol.id == store.getters['auth/getDirectorRol']) : false
    },
    isEcommerceAccess() {
      return this.canAccess(41) //Habilitar modulo de ecommerce
    },
    convertedUrl() {
      return this.shareUrl.replace("share", "embed");
    },
  },
  data() {
    return {
      shareUrl: "https://www.loom.com/share/81c0750e4dfe4d00b8ec37f9a28dd5b3?sid=5a2be04a-1baf-44a2-9394-969fa3b046a2",
    }
  },
  setup() {

    const showModal = ref(false)
    

    return { 
      showModal,      
    }
  },
}
</script>
