<template>
	<div class="navbar-container d-flex content align-items-center">

		<!-- Nav Menu Toggler -->
		<ul class="nav navbar-nav d-xl-none">
			<li class="nav-item">
				<b-link class="nav-link" @click="toggleVerticalMenuActive">
					<feather-icon icon="MenuIcon" size="21" />
				</b-link>
			</li>
		</ul>

		<!-- Left Col -->
		<div class="bookmark-wrapper align-items-center flex-grow-1 _d-none d-lg-flex">
			<!-- <bookmarks /> -->
		</div>

		<!-- Right Col -->
		<b-navbar-nav class="nav align-items-center ml-auto">
			<!-- <locale /> -->
			<!-- <dark-Toggler class="d-none d-lg-block" /> -->
			<!-- <cart-dropdown /> -->
			<help-toggler />

			<maintenance-toggler/>
			<search-product-toggler />
			<impersonate-dropdown v-if="isImpersonate && isActiveImpersonate" />
			<session-toggler />
			<revert-credit-limit />
			<!-- <search-bar /> -->
			
			<notification-dropdown />
			<user-dropdown />
		</b-navbar-nav>
	</div>
</template>

<script>
import {
	BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import RevertCreditLimit from './components/RevertCreditLimit.vue'
import SessionToggler from './components/SessionToggler.vue'
import ImpersonateDropdown from './components/ImpersonateDropdown.vue'
import SearchProductToggler from './components/SearchProductToggler.vue'
import HelpToggler from './components/HelpToggler.vue'
import MaintenanceToggler from './components/MaintenanceToggler.vue'
import store from '@/store'
import Search from '@/@core/components/articles/Search.vue'


export default {
	components: {
		BLink,

		// Navbar Components
		BNavbarNav,
		Bookmarks,
		Locale,
		DarkToggler,
		SearchBar,
		CartDropdown,
		NotificationDropdown,
		UserDropdown,
		RevertCreditLimit,
		SessionToggler,
		ImpersonateDropdown,
		SearchProductToggler,
		HelpToggler,
		MaintenanceToggler,
Search,
	},
	props: {
		toggleVerticalMenuActive: {
			type: Function,
			default: () => { },
		},
	},
	computed: {
		canRequestMaintenance() {
			return this.canAccess(62) //Permisos para solicitar mantenimiento
		},
		isAdministrator() {
			return store.getters['auth/getUser'] && store.getters['auth/getUser'].customRol.id == (store.getters['auth/getAdministratorRol'] || store.getters['auth/getRolDirector'] )
		},
		isActiveImpersonate () {
            return this.findSetting('ACTIVAR_CAMBIO_ACCESO_ADMIN')
        },
		isImpersonate() {
			if (store.getters['auth/getUser'] && store.getters['auth/getUser'].customRol.id != store.getters['auth/getAdministratorRol']) {
				return store.getters['auth/getIsImpersonate']
			}
			return true
		}
	},

}
</script>
